import React, { useEffect, useState } from 'react';
import styles from './Navbar.module.css';
import Logo from '../../Assets/Home/logo.png';
import { Link } from 'react-router-dom';
import menuSound from '../../Assets/Audio/link.mp3';

const Navbar = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const theme = isDarkMode ? 'dark' : 'light';
        document.documentElement.setAttribute('data-theme', theme);
    }, [isDarkMode]);

    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    let audio = new Audio(menuSound);

    const toggleMenu = () => {
        audio.play();
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const menuClose = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <header>
                <div className={styles.navbar}>
                    <div className={styles.logo}>
                        <img src={Logo} alt='Yeskondo Logo' />
                    </div>

                    <ul className={`${styles.menu} ${isMenuOpen ? styles.openmenu : styles.closemenu}`}>
                        <div className={styles.menu_address}>
                            <h2>ADDRESS</h2>
                            <p>Deccan, Pune -Maharashtra</p>
                        </div>
                        <div className={styles.menu_inner}>
                            <div className={styles.main_menulinks}>
                                <Link to='/' className={styles.links} onClick={menuClose}>Home</Link>
                                <Link to='/about' className={styles.links} onClick={menuClose}>About</Link>
                                <div className={styles.dropdown}>
                                    <Link className={styles.links} onClick={toggleDropdown}>Solution</Link>
                                    {isDropdownOpen && (
                                        <ul className={styles.dropdownMenu}>
                                            <li><Link className={styles.dropdownItem} to='/digital' onClick={menuClose}>DIGITAL MEDIA PLANNING</Link></li>
                                            <li><Link className={styles.dropdownItem} to='/oohmedia' onClick={menuClose}>OOH MEDIA PLANNING</Link></li>
                                            <li><Link className={styles.dropdownItem} to='/atlmedia' onClick={menuClose}>ATL MEDIA PLANNING</Link></li>
                                            <li><Link className={styles.dropdownItem} to='/btlmedia' onClick={menuClose}>BTL MEDIA PLANNING</Link></li>
                                            <li><Link className={styles.dropdownItem} to='/transitmedia' onClick={menuClose}>TRANSIT MEDIA PLANNING</Link></li>
                                            <li><Link className={styles.dropdownItem} to='/tvcmedia' onClick={menuClose}>TVC PAPER & RADIO</Link></li>
                                            <li><Link className={styles.dropdownItem} to='/event' onClick={menuClose}>EVENT PLANNING & EXECUTION</Link></li>
                                            <li><Link className={styles.dropdownItem} to='/filmproduction' onClick={menuClose}>FILM PRODUCTION</Link></li>
                                            <li><Link className={styles.dropdownItem} to='/offsetdesigning' onClick={menuClose}>OFFSET DESIGNING & PRINTING</Link></li>
                                            <li><Link className={styles.dropdownItem} to='/corporategifting' onClick={menuClose}>CORPORATE GIFTING & BRANDING MATERIAL</Link></li>
                                        </ul>
                                    )}
                                </div>
                                <Link to='/contact' className={styles.links} onClick={menuClose}>Contact</Link>

                                <Link to='/blog' className={styles.links} onClick={menuClose}>Blog</Link>
                            </div>
                        </div>
                    </ul>

                    <div className={styles.group_bar_theme}>
                        <div className={styles.bars} onClick={toggleMenu}>
                            <div className={styles.menuText}>
                                {isMenuOpen ? 'CLOSE' : 'MENU'}
                            </div>
                            <div>
                                <div className={`${styles.bar} ${isMenuOpen ? styles.barOpen : ''}`}></div>
                                <div className={`${styles.bar} ${isMenuOpen ? styles.barOpen : ''}`}></div>
                                <div className={`${styles.bar} ${isMenuOpen ? styles.barOpen : ''}`}></div>
                            </div>
                        </div>

                        <div className={styles.themeToggle}>
                            <button className={styles.themeButton} onClick={toggleTheme}>
                                {isDarkMode ? '☀️' : '🌙'}
                            </button>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Navbar;
