import React from 'react'
import styles from './Tvc.module.css'
import checkmar from '../../../Assets/check.png'
import tv from '../../../Assets/TVC/tv.png'
import Radio from '../../../Assets/TVC/radio.png'
import Print from '../../../Assets/TVC/printing-machine.png'
import tvchero from '../../../Assets/TVC/tvc_hero.png'
import execution from '../../../Assets/TVC/execution.png'
import { Link } from 'react-router-dom'

import broadcast from '../../../Assets/TVC/broadcast.png'
const Tvc = () => {
    return (
        <>
            <div className={styles.oohmedia}>



                <div className={styles.oohmedia_flexbox}>
                    <div className={styles.oohmedia_left}>
                        <h1 className={styles.orange}>TVC, Paper & Radio </h1>

                        <p>Capture audience attention on the go.</p>
                        <div className={styles.oohicons}>
                            <div className={styles.oohicon}>
                                <img src={broadcast} alt="Broadcast" />
                                {/* <h4>1T</h4> */}
                                <p>Broadcast Media Success</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={tv} alt="TV" />
                                {/* <h4>1.8M</h4> */}
                                <p>Creative TV Commercials</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={Radio} alt="Radio" />
                                {/* <h4>75</h4> */}
                                <p>Effective Radio Ads</p>
                            </div>
                        </div>
                        <Link to='/contact'><button className={styles.bookDemoButton}>Connect With Us</button></Link>
                    </div>
                    <div className={styles.ooh_right}>
                        <img src={tvchero} alt='OOH media' className={styles.ooh_right_image} />
                    </div>
                </div>
            </div>

            {/* Main Content  */}

            <div className={styles.ooh_maincontent}>
                <h2> <span className={styles.orange}>TVC, Paper & Radio</span> Media Planning</h2>
                <div className={styles.graphics}>
                    <div className={styles.ooh_main_content_graphics}>
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />	Television commercials (TVC) production</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Print media advertising</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Radio spots and jingles</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Comprehensive media buying and placement</li>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={tv} alt='public Transport' />
                                <h4>Television commercials (TVC) production</h4>

                                <p>Creating impactful TVCs involves crafting compelling narratives that resonate with viewers. High-quality production ensures that the message is delivered with clarity and visual appeal. Effective TVCs can significantly enhance brand recognition and drive consumer action through memorable storytelling. </p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.black_data} `}>

                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>
                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={Print} alt='Print' />
                                <h4>Print media advertising</h4>

                                <p>Print media advertising delivers detailed messaging and high-quality visuals through newspapers, magazines, and brochures. It remains a credible platform for building brand authority and trust.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <img src={Radio} alt='Radio' />
                                <h4>Radio spots and jingles</h4>

                                <p>of advertisers see improved ROI with strategic media planning</p>
                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.just_image} `}>
                                <img src='https://i.gifer.com/5uwq.gif' alt='Animation Gif' />

                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_push} `}>
                                <img src={execution} alt='execution' />
                                <h4>Comprehensive Media Buying and Placement</h4>

                                <p>We strategically purchase and place ads across TV, print, and radio to maximize reach and impact, ensuring optimal exposure for your brand.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Content */}

            <div className={styles.alt_advertising}>
                <div className={styles.alt_advertising_flexbox}>
                    <div className={styles.alt_advertising_left}>
                        <img src={tvchero} alt='TVC' />
                    </div>

                    <div className={styles.alt_advertising_right}>
                        <p>Our TVC, Paper, and Radio services cover the full spectrum of traditional media advertising. We produce captivating television commercials, create compelling print media ads, and craft engaging radio spots and jingles. With comprehensive media buying and placement, we ensure your advertisements reach the right audiences at the right times for maximum impact.</p>
                    </div>
                </div>

            </div>

            {/* Create Campaign */}

            <div className={styles.campaign}>
                <div className={styles.campaign_content}>
                    <h2>Create Your  Campaign With <span className={styles.orange} >Yeskondo</span></h2>
                    <Link to='/contact'><button className={styles.bookDemoButton}>Create</button></Link>
                </div>

            </div>


        </>
    )
}

export default Tvc