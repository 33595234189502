import React from 'react';
import styles from './Blog.module.css';

import digitalhero from '../../Assets/Digital/digitalhero.jpg';
import b2 from '../../Assets/Blog/b2.jpg';
import b3 from '../../Assets/Blog/seo.jpg';

const Blog = () => {
  return (
    <div className={styles.blog_page}>
      
      <div className={styles.featured_article}>
        <img src={digitalhero} alt='Featured' className={styles.featured_image} />
        <div className={styles.featured_content}>
          <h2>FEATURED ARTICLE</h2>
          <h1>Effective Strategies for Digital Marketing</h1>
          <p>Discover the most effective strategies to enhance your digital marketing efforts and drive results.</p>
          <div className={styles.author_info}>
            <p>Yeskondo</p>
           
          </div>
        </div>
      </div>
      <div className={styles.blog_list}>
        <div className={styles.blog_item}>
          <img src={b2} alt='Blog 2' />
          <div className={styles.blog_content}>
            <h4>MARKETING</h4>
            <h2>How to Leverage Social Media for Business Growth</h2>
            <p>Learn how to use social media platforms to grow your business and reach a wider audience effectively.</p>
          </div>
        </div>
        <div className={styles.blog_item}>
          <img src={b3} alt='Blog 3' />
          <div className={styles.blog_content}>
            <h4>SEO</h4>
            <h2>Top SEO Practices to Boost Your Website's Ranking</h2>
            <p>Implement these top SEO practices to improve your website's visibility and rank higher in search engine results.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
