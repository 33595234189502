import React from 'react';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';

import Footer from './Components/Footer/Footer';
import About from './Components/About/About';
import Gototop from './Components/Gototop/Gototop';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Logo from './Assets/Logo.png'
import ScrollToTop from './Components/ScrollToTop';
import Contact from './Components/Contact/Contact';
import DigitalMedia from './Components/Solutions/DigitalMedia/DigitalMedia';
import OohMedia from './Components/Solutions/OohMedia/OohMedia';
import Alt from './Components/Solutions/Alt/Alt';
import Transit from './Components/Solutions/Transit/Transit';
import Tvc from './Components/Solutions/Tvc/Tvc';
import Event from './Components/Solutions/Event/Event'
import Film from './Components/Solutions/Film/Film';
import Offsetdesigning from './Components/Solutions/Offsetdesign/Offsetdesign';
import Corporate from './Components/Solutions/Corporate/Corporate';
import Blt from './Components/Solutions/Blt/Blt';
import Blog from './Components/Blog/Blog';



const App = () => {
  return (
    <>
     
      
        <BrowserRouter basename='/' > {/* basename="/Yeskondo" */}
        <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} /> 
            <Route path='/about' element={<About/>}  />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/digital' element={<DigitalMedia/>}/>
            <Route path='/oohmedia' element={<OohMedia/>} />
            <Route path='/atlmedia' element={<Alt/>} />
            <Route path='/btlmedia' element={<Blt/>} />
            <Route path='/transitmedia' element={<Transit/>} />
            <Route path='/tvcmedia' element={<Tvc/>} />
            <Route path='event' element={<Event/>} />
            <Route path='/filmproduction' element={<Film/>}/>
            <Route path='/offsetdesigning' element={<Offsetdesigning/>} />
            <Route path='corporategifting' element={<Corporate/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/blog' element={<Blog/>} />
          </Routes>
          <Gototop/>  
          <FloatingWhatsApp phoneNumber="+919545797960" accountName="Yeskondo" avatar={Logo} darkMode={false} />
          <Footer/>
        </BrowserRouter>
     
    </>
  );
};

export default App;
