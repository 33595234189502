import React from 'react';
import styles from './Director.module.css';
import director from '../../../Assets/Employees/Director_processed.png';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const Director = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.1,    // Trigger when 10% of the component is visible
  });

  return (
    <>
      <div className={styles.director}>
        <div className={styles.director_flexbox}>
          <div className={`${styles.director_box} ${styles.director_box_left}`}>
            <img src={director} alt='Yeskondo Director Message' />
            <p>As a Business leader of Yeskondo Media, I'm proud to welcome you. We redefine creativity with captivating visuals, engaging content, and strategic marketing to drive results. Our passionate team is dedicated to supporting and guiding you to achieve your goals. Thank you for considering Yeskondo Media as your partner in success.</p>
          </div>
          <div className={`${styles.director_box} ${styles.director_box_right}`}>
            <div className={styles.overlay} />
            <div className={styles.director_attri} ref={ref}>
              <div className={styles.attri_box}>
                <h1>
                  {inView && <CountUp start={0} end={10} duration={2} />}+
                </h1>
                <p>Award Won</p>
              </div>
              <div className={styles.attri_box}>
                <h1>
                  {inView && <CountUp start={0} end={35} duration={2} />}
                </h1>
                <p>Employees</p>
              </div>
              <div className={styles.attri_box}>
                <h1>
                  {inView && <CountUp start={0} end={267} duration={2} />}+
                </h1>
                <p>Happy Clients</p>
              </div>
              <div className={styles.attri_box}>
                <h1>
                  {inView && <CountUp start={0} end={300} duration={2} />}+
                </h1>
                <p>Finished Projects</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Director;
