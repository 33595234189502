import React from 'react'
import styles from './Transit.module.css'
import checkmar from '../../../Assets/check.png'
import transithero from '../../../Assets/Transit/transit_hero.png'
import publict from '../../../Assets/Transit/public.png'
import vehicles from '../../../Assets/Transit/vehicles.png'
import campaign from '../../../Assets/Transit/campaign.png'
import transport from '../../../Assets/Transit/transport.gif'
import billboard from '../../../Assets/Transit/billboard.png'
import mobility from '../../../Assets/Transit/mobility.png'
import dynamic from '../../../Assets/Transit/connection.png'
import { Link } from 'react-router-dom'

const Transit = () => {
    return (
        <>
            <div className={styles.oohmedia}>



                <div className={styles.oohmedia_flexbox}>
                    <div className={styles.oohmedia_left}>
                        <h1 className={styles.orange}>Transit Media </h1>

                        <p>Capture audience attention on the go.</p>
                        <div className={styles.oohicons}>
                            <div className={styles.oohicon}>
                                <img src={billboard} alt="Transit Advertising Impact" />
                                {/* <h4>1T</h4> */}
                                <p>Transit Advertising Impact</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={mobility} alt="Mobility Ad Solutions" />
                                {/* <h4>1.8M</h4> */}
                                <p>Mobility Ad Solutions</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={dynamic} alt="Dynamic Transit Campaigns" />
                                {/* <h4>75</h4> */}
                                <p>Dynamic Transit Campaigns</p>
                            </div>
                        </div>
                        <Link to='/contact'><button className={styles.bookDemoButton}>Connect With Us</button></Link>
                    </div>
                    <div className={styles.ooh_right}>
                        <img src={transithero} alt='OOH media' className={styles.ooh_right_image} />
                    </div>
                </div>
            </div>

            {/* Main Content  */}

            <div className={styles.ooh_maincontent}>
                <h2> <span className={styles.orange}>Transit</span> Media Planning</h2>
                <div className={styles.graphics}>
                    <div className={styles.ooh_main_content_graphics}>
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />	Advertising on public transportation</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />High commuter visibility</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Buses, trains, taxis, and subways</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Mobile and dynamic campaigns</li>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={publict} alt='public Transport' />
                                <h4>Advertising on public transportation</h4>

                                <p>Transit Media Planning places your brand in front of commuters through advertising on public transportation. </p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.black_data} `}>

                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>
                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src='https://cdn.prod.website-files.com/60859154db955843c7fc06e7/65c744f9d92b5470dcd3c3d3_Capture%20Attention.svg' alt='Visibility' />
                                <h4>High commuter visibility</h4>

                                <p>Whether it's buses, trains, taxis, or subways, we ensure high visibility for your message in mobile and dynamic environments. </p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <img src={vehicles} alt='Transit Media' />
                                <h4>Buses, trains, taxis, and subways</h4>

                                <p>of advertisers see improved ROI with strategic media planning</p>
                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.just_image} `}>
                                <img src={transport} alt='transport' />

                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_push} `}>
                                <img src={campaign} alt='campaign' />
                                <h4>Mobile and dynamic campaigns</h4>

                                <p>Capture attention with flexible, real-time marketing strategies optimized for mobile platforms. Engage users with interactive content and timely updates, driving higher engagement and conversion rates.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Content */}

            <div className={styles.alt_advertising}>
                <div className={styles.alt_advertising_flexbox}>
                    <div className={styles.alt_advertising_left}>
                        <img src={transithero} alt='ALT Media Planing' />
                    </div>

                    <div className={styles.alt_advertising_right}>
                        <p>Transit Media Planning places your brand in front of commuters through advertising on public transportation. Whether it's buses, trains, taxis, or subways, we ensure high visibility for your message in mobile and dynamic environments. Our transit media campaigns take advantage of the constant movement of public transportation to maximize reach and engagement.</p>
                    </div>
                </div>

            </div>

            {/* Create Campaign */}

            <div className={styles.campaign}>
                <div className={styles.campaign_content}>
                    <h2>Create Your  Campaign With <span className={styles.orange} >Yeskondo</span></h2>
                    <Link to='/contact'><button className={styles.bookDemoButton}>Create</button></Link> 
                </div>

            </div>


        </>
    )
}

export default Transit