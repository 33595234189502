import React from 'react'
import AboutHero from './AboutHero/AboutHero'
import Mission from './Mvv/Mvv'
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us - Yeskondo Media Pvt Ltd</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="Learn about Yeskondo Media Pvt Ltd, a leader in digital marketing, web development, and SEO services. Discover our mission, values, and commitment to transforming your digital presence." />
        <meta name="keywords" content="About Yeskondo Media Pvt Ltd, company mission, digital marketing, web development, SEO services, values, team" />
        <meta name="author" content="Yeskondo Media Pvt Ltd" />
        
        {/* Open Graph (OG) Tags */}
        <meta property="og:title" content="About Us - Yeskondo Media Pvt Ltd" />
        <meta property="og:description" content="Learn more about Yeskondo Media Pvt Ltd, our mission, values, and the digital solutions we offer to help your business succeed online." />
        <meta property="og:image" content="your-about-page-og-image-url.jpg" />
        <meta property="og:url" content="https://www.yeskondo.com/about" />
        
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - Yeskondo Media Pvt Ltd" />
        <meta name="twitter:description" content="Yeskondo Media Pvt Ltd - Committed to delivering top-notch digital marketing, web development, and SEO services to enhance your digital presence." />
        <meta name="twitter:image" content="your-about-page-twitter-image-url.jpg" />
      </Helmet>
      
      <AboutHero />
      <Mission />
    </>
  )
}

export default About;
