import React from 'react';
import styles from './Mvv.module.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; 

import c1 from '../../../Assets/Clients/Our Clients-01.png'
import c2 from '../../../Assets/Clients/Our Clients-02.png'
import c3 from '../../../Assets/Clients/Our Clients-03.png'
import c4 from '../../../Assets/Clients/Our Clients-04.png'
import c5 from '../../../Assets/Clients/Our Clients-05.png'
import c6 from '../../../Assets/Clients/Our Clients-06.png'
import c7 from '../../../Assets/Clients/Our Clients-07.png'
import c8 from '../../../Assets/Clients/Our Clients-08.png'
import c9 from '../../../Assets/Clients/Our Clients-09.png'
import c10 from '../../../Assets/Clients/Our Clients-10.png'
import c11 from '../../../Assets/Clients/Our Clients-11.png'
import c12 from '../../../Assets/Clients/Our Clients-12.png'
import c13 from '../../../Assets/Clients/Our Clients-13.png'
import c14 from '../../../Assets/Clients/Our Clients-14.png'
import c15 from '../../../Assets/Clients/Our Clients-15.png'
import c16 from '../../../Assets/Clients/Our Clients-16.png'
import c17 from '../../../Assets/Clients/Our Clients-17.png'
import c18 from '../../../Assets/Clients/Our Clients-18.png'
import c19 from '../../../Assets/Clients/Our Clients-19.png'
import c20 from '../../../Assets/Clients/Our Clients-20.png'
import c21 from '../../../Assets/Clients/Our Clients-21.png'
import c22 from '../../../Assets/Clients/Our Clients-22.png'
import c23 from '../../../Assets/Clients/Our Clients-23.png'
import c24 from '../../../Assets/Clients/Our Clients-24.png'
import c25 from '../../../Assets/Clients/Our Clients-25.png'


const Mvv = () => {
  
  const clientImages = [
      c1, c2, c3, c4,c5, c6,c7,c8,c9,c10,c11,c12,c13,c14,c15,c16,c17,c18,c19,c20,c21,c22,c23,c24,c25
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div className={styles.mvv}>
     
      <div className={styles.mvv_container}>
        <div className={styles.mvv_flexbox}>
          {/* Left Box */}
          <div className={`${styles.box} ${styles.left_box}`}>
            <div className={styles.left_box_bg}>
              <div className={styles.overlay}></div>
              <div className={styles.left_box_content}>
                <h2>Our Mission</h2>
                <p>Our mission is to empower individuals and organizations with innovative solutions, fostering growth, and driving positive change.</p>
                <h3>Get in touch with us: -</h3>
                <div className={styles.icons}>
                  <i className="fa-solid fa-phone"></i>
                  <p>+91 99229 66739</p>
                </div>
                <div className={styles.icons}>
                  <i className="fa-solid fa-envelope"></i>
                  <p>info@yeskondomedia.com</p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Box */}
          <div className={`${styles.box} ${styles.right_box}`}>
            <div className={styles.globe_container}>
           
            </div>
            <div className={styles.right_box_layout}>
              <div className={styles.content_box}>
                <h2>Mission</h2>
                <p>Yeskondo's mission is to empower through innovation, delivering transformative solutions that exceed expectations. We foster growth and drive positive change, setting new standards in our field.</p>
              </div>
              <div className={styles.content_box}>
                <h2>Vision</h2>
                <p>Yeskondo envisions becoming a pioneering media solutions provider celebrated for creativity, strategy, and impactful results. We aim to lead the competitive landscape with innovative approaches that redefine success.</p>
              </div>
              <div className={styles.content_box}>
                <h2>Goal</h2>
                <p>Our goal at Yeskondo is to maximize your brand's potential through cutting-edge media solutions and unwavering dedication. We strive to deliver transformative outcomes that exceed expectations and drive long-term growth.</p>
              </div>
              <div className={styles.content_box}>
                <h2>Values</h2>
                <p>At Yeskondo, our values are anchored in innovation, integrity, and collaboration. We prioritize creativity and excellence in all our endeavors, ensuring impactful solutions and enduring client relationships.</p>
              </div>
            </div>
          </div>

           {/* Other content of About component */}
        </div>

        
      </div>
     {/* Our Clients */}
     <div className={styles.our_clients}>
      <h2>Our Clients</h2>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          customTransition="all 0.5s"
          transitionDuration={500}
        >
          {clientImages.map((src, index) => (
            <div key={index} className={styles.client_image}>
              <img src={src} alt={`Client ${index}`} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Mvv;