import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Hero.module.css';
import slide01 from '../../../Assets/Home/Slider/slide01.webp';
import slide02 from '../../../Assets/Home/Slider/slide02.webp';
import slide03 from '../../../Assets/Home/Slider/slide03.webp';

const Hero = () => {
    const [currentSlide, setCurrentSlide] = useState(0); // State to track current slide index
    const sliderRef = React.useRef(null); // Ref to access the Slider component

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        arrows: false, // Hide default arrows
        afterChange: (current) => setCurrentSlide(current), // Update current slide index
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev(); // Go to previous slide
    };

    const goToNext = () => {
        sliderRef.current.slickNext(); // Go to next slide
    };

    const handleCall = () => {
        window.open('tel:+919922966739', '_blank'); // Open default calling app
    };

    return (
        <div className={styles.hero}>
            <div className={styles.frame}>
                <div className={styles.sound}>
                    <p>OFF</p>
                    <div className={styles.equalizer}></div>
                    <p>SOUND</p>
                </div>
                <div className={styles.nav}>
                    <p className={`${styles.prev} ${styles.np_btn}`} onClick={goToPrev}></p>
                    <p className={`${styles.next} ${styles.np_btn}`} onClick={goToNext}></p>
                </div>
                <div className={styles.counter}>
                    <p>{`${currentSlide + 1}`}</p>
                </div>
            </div>
            <Slider ref={sliderRef} {...settings} className={styles.hero_slider}>
                <div className={styles.slide}>
                    <img src={slide01} alt="Slide 1" className={styles.sliderImage} />
                    <div className={styles.content}>
                        <h1 className={styles.title}>Comprehensive 360° Marketing Strategies</h1>
                        <button className={styles.ghostbutton} onClick={handleCall}></button>
                    </div>
                </div>
                <div className={styles.slide}>
                    <img src={slide02} alt="Slide 2" className={styles.sliderImage} />
                    <div className={styles.content}>
                        <h1 className={styles.title}>Build Your Brand Presence with Advanced Digital Marketing</h1>
                        <button className={styles.ghostbutton} onClick={handleCall}></button>
                    </div>
                </div>
                <div className={styles.slide}>
                    <img src={slide03} alt="Slide 3" className={styles.sliderImage} />
                    <div className={styles.content}>
                        <h1 className={styles.title}>Unleash Your Brand’s Potential with Innovative Design Solutions</h1>
                        <button className={styles.ghostbutton} onClick={handleCall}></button>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default Hero;
 