import React from 'react';
import styles from './OohMedia.module.css';
import oohhero from '../../../Assets/OOH/ooh.jpg';
import checkmar from '../../../Assets/check.png'
// import bilboard from '../../../Assets/OOH/bilboard.png'
import bilboard2 from '../../../Assets/OOH/billboard2.png'
import location from '../../../Assets/OOH/location.png'
import { Link } from 'react-router-dom';
const OohMedia = () => {
    
    return (
        <>
            {/* ooh media hero section  */}
            <div className={styles.oohmedia}>
                <div className={styles.oohmedia_flexbox}>
                    <div className={styles.oohmedia_left}>
                        <h1 className={styles.orange}>OOH Media Planning</h1>
                        <h2>Out-of-Home</h2>
                        <h2>(OOH)</h2>
                        <p>Capture audience attention on the go.</p>
                        <div className={styles.oohicons}>
                            <div className={styles.oohicon}>
                                <img src='https://cdn.prod.website-files.com/60859154db955843c7fc06e7/65c314288570d3759457192f_icon-imperessions.svg' alt="Impressions Icon" />
                                {/* <h4>1T</h4> */}
                                <p>Outdoor Visibility Achieved</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src='https://cdn.prod.website-files.com/60859154db955843c7fc06e7/6672b3df236a7360a874872c_screen.svg' alt="Screens Icon" />
                                {/* <h4>1.8M</h4> */}
                                <p>Strategic Ad Placement</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src='https://cdn.prod.website-files.com/60859154db955843c7fc06e7/6672b3ecf8fba874cc296b59_loaction.svg' alt="Spot Types Icon" />
                                {/* <h4>75</h4> */}
                                <p>Enhanced Public Reach</p>
                            </div>
                        </div>
                   <Link to='/contact'><button className={styles.bookDemoButton}>Connect With Us</button></Link>     
                    </div>
                    <div className={styles.ooh_right}>
                        <img src={oohhero} alt='OOH media' className={styles.ooh_right_image} />
                    </div>
                </div>

            </div>

            {/* ooh media main content */}
            <div className={styles.ooh_maincontent}>
                <h2> <span className={styles.orange}>OOH </span>Media Planning</h2>
                <div className={styles.graphics}>
                    <div className={styles.ooh_main_content_graphics}>
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />High-visibility outdoor advertising</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Strategic location selection</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Large-format billboards and digital screens</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Impactful brand presence</li>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={bilboard2} alt='bilboard2' />
                                <h4>High-Visibility Outdoor Advertising</h4>
                                
                                <p>We utilize strategic locations for billboards and transit ads to ensure maximum exposure and engagement with your target audience.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.black_data} `}>

                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>
                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={location}  alt='location'/>
                                <h4>Strategic location selection</h4>
                              
                                <p>We pinpoint high-traffic areas for optimal ad placement, maximizing visibility and audience reach.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <img src='https://cdn.prod.website-files.com/60859154db955843c7fc06e7/65c7437a4625ef0ba7129709_Performance%20icon.svg' alt='Money' />
                                <h4>Earn Favorability</h4>
                                <h1>85%</h1>
                                <p>of viewers find ads useful</p>
                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.just_image} `}>
                                <img src='https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExc2Fna3B2MWU2Mzl4eWhqc2dqY2J2ZzM1em9id2JqeHl5aTh3MDg3YyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/f8hiiWphq4MwWsOQ2B/giphy.gif' alt='bilboard'/>
                               
                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_push} `}>
                                <img src='https://cdn.prod.website-files.com/60859154db955843c7fc06e7/65c7455cba1cd71484c9e50b_CTR%20icon.svg' alt='Push' />
                                <h4>Push Actions</h4>
                                <h1>66%</h1>
                                <p>of consumers report that engaging with a ad them to search for the brand online</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            {/* Create Campaign */}

            <div className={styles.campaign}>
                <div className={styles.campaign_content}>
                    <h2>Create Your  Campaign With <span className={styles.orange} >Yeskondo</span></h2>
                  <Link to='/contact'><button className={styles.bookDemoButton}>Create</button></Link>  
                </div>

            </div>
        </>
    );
};

export default OohMedia;
