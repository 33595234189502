import React, { useState } from 'react';
import styles from './Employees.module.css';
import ScrollAnimation from 'react-animate-on-scroll';
import lalit from '../../../Assets/Employees/Director_processed.png';
import sharayu from '../../../Assets/Employees/Sharayu.png';
import Akash from '../../../Assets/Employees/Akash.png';
import Kavita from '../../../Assets/Employees/Kavita.png';
import Manisha from '../../../Assets/Employees/Manisha.png';
import Satish from '../../../Assets/Employees/Satish.png';
import Sharad from '../../../Assets/Employees/Sharad.png';
import Sunil from '../../../Assets/Employees/Sunil.png';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const Employees = () => {
  const [modalEmployee, setModalEmployee] = useState(null);

  const openModal = (employee) => setModalEmployee(employee);
  const closeModal = () => setModalEmployee(null);

  const employees = [
    { name: 'Kavita Jagtap', image: Kavita, position: 'Head Of Digital', about: 'Hi, I\'m Kavita Jagtap, the Digital Head at Yeskondo Media, leading our digital strategies to new heights.' },

    { name: 'Sunil Patil', image: Sunil, position: 'Director- business & Operations', about: 'Hi, I\'m Sunil Patil, the Director- business & Operations at Yeskondo Media, leading our digital strategies to new heights.' },

    { name: 'Satish Gejage', image: Satish, position: 'Event And Production Head', about: 'Hi, I\'m Satish Gejage, the Event and Production Head, making sure our events and productions are top-notch.' },

    { name: 'Sharad Chavhan', image: Sharad, position: 'Operations Head', about: 'Hi, I\'m Sharad Chavhan, the Operations Head, keeping everything running smoothly at Yeskondo Media.' },

    { name: 'Manisha Bhagat', image: Manisha, position: 'Content Creator', about: 'Hi, I\'m Manisha Bhagat, a Content Creator, and I love bringing fresh stories and ideas to life.' },
    
    { name: 'Akshay Gaikwad', image: Akash, position: 'Video Editor', about: 'Hi, I\'m Akshay Gaikwad, the Video Editor at Yeskondo Media, where I craft compelling visual narratives.' },
  ];

  const directors = [
    { name: 'Lalit Kedare', image: lalit, position: 'Lead Business', about: 'Hi, I\'m Lalit Kedare, the Lead Business at Yeskondo Media, driving growth and business opportunities.' },
    { name: 'Sharayu Kedare', image: sharayu, position: 'Director', about: 'Hi, I\'m Sharayu Kedare, leading the team at Yeskondo Media and ensuring collaboration and success.' }
  ];

  return (
    <div className={styles.employees}>
      <div className={styles.heading}>
        <ScrollAnimation animateIn='slideInLeft' animateOut='slideOutLeft' animateOnce={true}>
          <h2>OUR <span className={styles.color_orange}>TEAM</span></h2>
        </ScrollAnimation>
        <div className={styles.lines}>
          <ScrollAnimation animateIn='slideInRight' animateOut='slideOutRight'>
            <div className={`${styles.line} ${styles.line1}`}></div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='slideInRight' animateOut='slideOutRight'>
            <div className={`${styles.line} ${styles.line2}`}></div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='slideInRight' animateOut='slideOutRight'>
            <div className={`${styles.line} ${styles.line3}`}></div>
          </ScrollAnimation>
        </div>
      </div>

      <div className={styles.employees_directors}>
        {directors.map((director, index) => (
          <ScrollAnimation key={index} animateIn={index % 2 === 0 ? 'slideInLeft' : 'slideInRight'} animateOnce={true}>
            <Link to='' onClick={() => openModal(director)} className={styles.nostyles}>
              <img src={director.image} alt={director.name} />
              <p>{director.name}</p>
            </Link>
          </ScrollAnimation>
        ))}
      </div>

      <div className={styles.employees_heads}>
        {employees.map((employee, index) => (
          <ScrollAnimation key={index} animateIn={index % 2 === 0 ? 'slideInRight' : 'slideInLeft'} animateOnce={true}>
            <Link to='' onClick={() => openModal(employee)} className={styles.nostyles}>
              <img src={employee.image} alt={employee.name} />
              <p>{employee.name}</p>
            </Link>
          </ScrollAnimation>
        ))}
      </div>

      {modalEmployee && <Modal closeModal={closeModal} employee={modalEmployee} />}
    </div>
  );
};

export default Employees;
