import React from 'react'

import Heroabt from './Heroabt/Heroabt'
import Employees from './Employees/Employees'
import Portfolio from './Portfolio/Portfolio'

import Director from './Director/Director'
import { Helmet } from 'react-helmet';
import Homeservice from './Homeservice/Homeservice'
import Clients from './Clients/Clients'
import Heronew from './Hero/Heronew'
import Hero from './Hero/Hero'


const Home = () => {
  return (
    <>
      <Helmet>
        <title>Transforming Digital Presence: Yeskondo Media Pvt Ltd</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="Yeskondo Media Pvt Ltd - Your trusted partner for digital marketing, web development, and SEO services. Transform your digital presence with our expert solutions." />
        <meta name="keywords" content="Yeskondo Media Pvt Ltd, digital marketing, web development, SEO services, digital presence" />
        <meta name="author" content="Yeskondo Media Pvt Ltd" />
        <meta property="og:title" content="Transforming Digital Presence: Yeskondo Media Pvt Ltd - Digital Marketing, Web Development, SEO Services" />
        <meta property="og:description" content="Yeskondo Media Pvt Ltd - Your trusted partner for digital marketing, web development, and SEO services. Transform your digital presence with our expert solutions." />
        <meta property="og:image" content="your-og-image-url.jpg" />
        <meta property="og:url" content="/Fav.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Transforming Digital Presence: Yeskondo Media Pvt Ltd - Digital Marketing, Web Development, SEO Services" />
        <meta name="twitter:description" content="Yeskondo Media Pvt Ltd - Your trusted partner for digital marketing, web development, and SEO services. Transform your digital presence with our expert solutions." />
        <meta name="twitter:image" content="your-twitter-image-url.jpg" />
      </Helmet>
      <Hero/>
      <Heroabt/>
      <Homeservice/>
      <Employees />
      <Portfolio />
      <Director />
      <Clients/>

    </>
  )
}

export default Home