import React from 'react';
import styles from './Homeservice.module.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';


const Homeservice = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.homeservice}>
      <div className={styles.homeservice_heading}>
        <h2>Our Services</h2>
        <p>Explore our comprehensive range of services designed to elevate your digital presence and marketing strategies.</p>
      </div>

      <div className={styles.homeservice_main}>
        <Slider {...settings} className={styles.main_setting}>

          <div className={styles.cover}>
          <div className={styles.servicebox}>
            <h3>Digital Marketing</h3>
            <p>Boost your online visibility and engagement with our tailored digital marketing strategies, including SEO, PPC, and social media management.</p>
            <p><Link to='/digital' className={styles.readmore}>Discover More</Link></p>
          </div>
          </div>


          

          <div className={styles.cover}>
          <div className={styles.servicebox}>
            <h3>OOH Media Planning</h3>
            <p>Effective Out-of-Home advertising to capture your audience’s attention through strategic placements and impactful designs.</p>
            <p><Link to='/oohmedia' className={styles.readmore}>Learn More</Link></p>
          </div>
          </div>


          <div className={styles.cover}>
          <div className={styles.servicebox}>
          <h3>ATL Media Planning</h3>
          <p>Reach a broad audience with our Above-The-Line media planning, including TV, radio, and print advertising.</p>
          <p><Link to='/atlmedia' className={styles.readmore}>Find Out More</Link></p>
        </div>
        </div>


        <div className={styles.cover}>
        <div className={styles.servicebox}>
          <h3>BTL Media Planning</h3>
          <p>Target specific groups with our Below-The-Line media strategies, such as direct marketing and event promotions.</p>
          <p><Link to='/btlmedia' className={styles.readmore}>Get Details</Link></p>
        </div>
        </div>


        <div className={styles.cover}>
        <div className={styles.servicebox}>
          <h3>Transit Media Planning</h3>
          <p>Engage commuters and travelers with our innovative transit media placements designed for maximum visibility.</p>
          <p><Link to='/transitmedia' className={styles.readmore}>Explore More</Link></p>
        </div>
        </div>


        <div className={styles.cover}>
        <div className={styles.servicebox}>
          <h3>TVC, Paper & Radio</h3>
          <p>Comprehensive TV Commercials, print media, and radio advertising solutions to amplify your brand’s reach.</p>
          <p><Link to='/tvcmedia' className={styles.readmore}>View Services</Link></p>
        </div>
        </div>


        <div className={styles.cover}>
        <div className={styles.servicebox}>
          <h3>Event Planning & Execution</h3>
          <p>From concept to execution, we manage every detail of your event to ensure its success and seamless operation.</p>
          <p><Link to='/event' className={styles.readmore}>See How</Link></p>
        </div>
        </div>



        <div className={styles.cover}>
        <div className={styles.servicebox}>
          <h3>Film Production</h3>
          <p>Bring your vision to life with our professional film production services, including scripting, shooting, and editing.</p>
          <p><Link to='/filmproduction' className={styles.readmore}>Learn More</Link></p>
        </div>
        </div>


        <div className={styles.cover}>
        <div className={styles.servicebox}>
          <h3>Offset Designing & Printing</h3>
          <p>High-quality offset printing and design services for all your marketing materials, from brochures to business cards.</p>
          <p><Link to='/offsetdesigning' className={styles.readmore}>Get More Info</Link></p>
        </div>
        </div>



        <div className={styles.cover}>
        <div className={styles.servicebox}>
          <h3>Corporate Gifting & Branding Material</h3>
          <p>Create lasting impressions with customized corporate gifts and branding materials tailored to your brand’s identity.</p>
          <p><Link to='/corporategifting' className={styles.readmore}>Discover More</Link></p>
        </div>
        </div>
          {/* Add more service boxes here if needed */}
        </Slider>
      </div>
    </div>
  );
}

  export default Homeservice;