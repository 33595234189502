import React from 'react';
import styles from './Modal.module.css';
import logo from '../../../Assets/Logo.png';

const Modal = ({ closeModal, employee }) => {
  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <img src={logo} alt='Yeskondo Logo' className={styles.logo} />
          <h2>Let's Meet The Team</h2>
        </div>
        <div className={styles.body}>
          <div className={styles.left}>
            <h1>{employee.position}</h1>
            <p className={styles.position}></p>
            <p className={styles.about}>{employee.about}</p>
            <p className={styles.handle}>@yeskondo_media_pvt_ltd</p>
            <div className={styles.border_bottom}></div>
          </div>
          <div className={styles.right}>
            <img src={employee.image} alt={employee.name} className={styles.employeeImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
