import React from 'react'
import styles from './Film.module.css'
import checkmar from '../../../Assets/check.png'
import Script from '../../../Assets/Film/Script.png'
import management from '../../../Assets/Event/management.png'
import Logistics from '../../../Assets/Event/Logistics.png'
import Filmhero from '../../../Assets/Film/film_hero.png'
import filmproduction from '../../../Assets/Film/film.gif'
import camera from '../../../Assets/Film/camera.png'
import Professional from '../../../Assets/Film/Professional.png'
import Highquality from '../../../Assets/Film/Highquality.png'
import film from '../../../Assets/Film/film.png'
import { Link } from 'react-router-dom'



const Film = () => {
    return (
        <>
            <div className={styles.oohmedia}>



                <div className={styles.oohmedia_flexbox}>
                    <div className={styles.oohmedia_left}>
                        <h1 className={styles.orange}>Film Production</h1>


                        <div className={styles.oohicons}>
                            <div className={styles.oohicon}>
                                <img src={Professional} alt="Professional" />
                                {/* <h4>1T</h4> */}
                                <p>Professional Film Creation</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={Highquality} alt="High-Quality Productions" />
                                {/* <h4>1.8M</h4> */}
                                <p>High-Quality Productions</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={film} alt="Spot Types Icon" />
                                {/* <h4>75</h4> */}
                                <p>Cinematic Excellence Delivered</p>
                            </div>
                        </div>
                        <Link to='/contact'><button className={styles.bookDemoButton}>Connect With Us</button></Link>
                    </div>
                    <div className={styles.ooh_right}>
                        <img src={Filmhero} alt='OOH media' className={styles.ooh_right_image} />
                    </div>
                </div>
            </div>

            {/* Main Content  */}

            <div className={styles.ooh_maincontent}>
                <h2> <span className={styles.orange}>Film Production</span> </h2>
                <div className={styles.graphics}>
                    <div className={styles.ooh_main_content_graphics}>
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Scriptwriting and storyboarding</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Casting and location scouting</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Filming and post-production</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Promotional trailers and distribution</li>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={Script} alt='Script' />
                                <h4>Scriptwriting and Storyboarding</h4>

                                <p>Crafting compelling scripts and detailed storyboards to bring creative visions to life. These essential elements guide the production process, ensuring a cohesive and impactful final product.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.black_data} `}>

                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>
                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={Logistics} alt='Logistics' />
                                <h4>Casting and location scouting</h4>

                                <p>Choosing the right venue and managing logistics are crucial for the success of any event. This includes selecting a location that fits the event's theme and audience, while also coordinating transportation, accommodations, and on-site services.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <img src={management} alt='management' />
                                <h4>On-site management and coordination</h4>

                                <p>Ensuring seamless execution through on-site management and coordination. This includes overseeing event setup, managing staff, and addressing any issues that arise to ensure a smooth and successful event.</p>
                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.just_image} `}>
                                <img src={filmproduction} alt='Flim Production' />

                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_push} `}>
                                <img src={camera} alt='Camera' />
                                <h4>Filming and Post-Production</h4>

                                <p>Capturing high-quality footage with precision and creativity to bring stories to life. Enhancing the final product through expert editing, sound design, and visual effects.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Content */}

            <div className={styles.alt_advertising}>
                <div className={styles.alt_advertising_flexbox}>
                    <div className={styles.alt_advertising_left}>
                        <img src={Filmhero} alt='TVC' />
                    </div>

                    <div className={styles.alt_advertising_right}>
                        <p>Our Film Production services bring your stories to life with high-quality cinematic techniques. We handle everything from scriptwriting and storyboarding to casting and location scouting. Our expert team manages the filming process and post-production, including editing, sound design, and special effects. We also create promotional trailers and assist with distribution, ensuring your film reaches the intended audience.</p>
                    </div>
                </div>

            </div>

            {/* Create Campaign */}

            <div className={styles.campaign}>
                <div className={styles.campaign_content}>
                    <h2>Create Your  Campaign With <span className={styles.orange} >Yeskondo</span></h2>
                    <Link to='/contact'><button className={styles.bookDemoButton}>Create</button></Link>
                </div>

            </div>


        </>
    )
}

export default Film