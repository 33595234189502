import React from 'react'
import styles from './Corporate.module.css'


import Corporate_hero from '../../../Assets/Corporate/Corporate_hero.png'
import checkmar from '../../../Assets/check.png'
import corporate_gifts from '../../../Assets/Corporate/corporate_gifts.png'
import promotional_items from '../../../Assets/Corporate/promotional_items.png'
import client_appreciation from '../../../Assets/Corporate/client_appreciation.png'
import surprise from '../../../Assets/Corporate/Giftbox.gif'
import high_quality from '../../../Assets/Corporate/high_quality.png'


import Brand from '../../../Assets/Corporate/Brand.png'
import corporate from '../../../Assets/Corporate/Corporate.png'
import Effective from '../../../Assets/Corporate/Effective.png'
import { Link } from 'react-router-dom'

const Corporate = () => {
    return (
        <>
            <div className={styles.oohmedia}>



                <div className={styles.oohmedia_flexbox}>
                    <div className={styles.oohmedia_left}>
                        <h1 className={styles.orange}>Corporate Gifting & Branding Material</h1>


                        <div className={styles.oohicons}>
                            <div className={styles.oohicon}>
                                <img src={Brand} alt="Branded Gifts Provided" />
                                {/* <h4>1T</h4> */}
                                <p>Branded Gifts Provided</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={corporate} alt="Corporate Gifts Delivered" />
                                {/* <h4>1.8M</h4> */}
                                <p>Corporate Gifts Delivered</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={Effective} alt="Effective Brand Material" />
                                {/* <h4>75</h4> */}
                                <p>Effective Brand Material</p>
                            </div>
                        </div>
                        <Link to='/contact'><button className={styles.bookDemoButton}>Connect With Us</button></Link>
                    </div>
                    <div className={styles.ooh_right}>
                        <img src={Corporate_hero} alt='OOH media' className={styles.ooh_right_image} />
                    </div>
                </div>
            </div>

            {/* Main Content  */}

            <div className={styles.ooh_maincontent}>
                <h2> <span className={styles.orange}>Corporate Gifting & Branding Material</span> </h2>
                <div className={styles.graphics}>
                    <div className={styles.ooh_main_content_graphics}>
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Customized corporate gifts</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Branded promotional items</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Employee recognition and client appreciation</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />High-quality materials and design</li>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={corporate_gifts} alt='corporate_gifts' />
                                <h4>Customized corporate gifts</h4>

                                <p>Tailored gifts that reflect your brand identity and values. Enhance client relationships and employee satisfaction with personalized and memorable items. Perfect for events, milestones, and holidays.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.black_data} `}>

                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>
                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={promotional_items} alt='promotional_items' />
                                <h4>Branded promotional items</h4>

                                <p>Increase brand visibility with custom promotional products. Engage your audience at events and trade shows. Versatile options tailored to your marketing needs.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <img src={client_appreciation} alt='client_appreciation' />
                                <h4>Employee recognition and client appreciation</h4>

                                <p>Boost morale and loyalty with personalized awards and gifts. Strengthen relationships by acknowledging achievements and milestones. Foster a culture of appreciation and gratitude.</p>
                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.just_image} `}>
                                <img src={surprise} alt='gift' />

                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_push} `}>
                                <img src={high_quality} alt='Largeprint' />
                                <h4>High-Quality Materials and Design</h4>

                                <p>Ensure durability and appeal with premium materials. Stand out with innovative and attractive designs. Enhance brand perception through superior quality products.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Content */}

            <div className={styles.alt_advertising}>
                <div className={styles.alt_advertising_flexbox}>
                    <div className={styles.alt_advertising_left}>
                        <img src={Corporate_hero} alt='TVC' />
                    </div>

                    <div className={styles.alt_advertising_right}>
                        <p>Our Corporate Gifting & Branding Material services provide customized solutions to enhance your brand's presence. We offer a wide range of branded promotional items and corporate gifts tailored to your needs. Whether for employee recognition or client appreciation, our high-quality materials and designs make a lasting impression. Let Yeskondo Media help you show your appreciation with style and sophistication.</p>
                    </div>
                </div>

            </div>

            {/* Create Campaign */}

            <div className={styles.campaign}>
                <div className={styles.campaign_content}>
                    <h2>Create Your  Campaign With <span className={styles.orange} >Yeskondo</span></h2>
                    <Link to='/contact'><button className={styles.bookDemoButton}>Create</button></Link>
                </div>

            </div>


        </>
    )
}

export default Corporate