import React from 'react'
import styles from './Heroabt.module.css'
import img1 from '../../../Assets/Home/1.jpg'
import img2 from '../../../Assets/Home/2.jpg'
import { Link } from 'react-router-dom'



const Heroabt = () => {
  return (
    <>
    <div className={styles.heroabt}>
  
        <div className={styles.flexbox}>
            <div className={styles.leftbox}>
                <div className={styles.left_content}>
                    <p>OUR STORY</p>
                    <h1><span className={styles.orange} >YESKONDO</span> A Creative 360° Media Planning Agency in Pune, India</h1>
                    <p>At Yeskondo Media Pvt. Ltd., established in 2020, we pride ourselves on being a dynamic media solutions provider. Specializing in 360° Media, we offer a comprehensive suite of services to elevate your brand presence.</p>

                    <div className={styles.flexbox}>
                            <Link to='/about'><button className={styles.morebutton}>MORE ABOUT US</button></Link>
                            <p><Link className={styles.portfoliolink}>VIEW PORTFOLIO</Link></p>
                    </div>
                </div>
            </div>

            <div className={styles.rightbox}>
                
                <img src={img1} alt='background' className={styles.bgImage} />
                <img src={img2} alt='background2'className={styles.fgImage} />
            </div>

        </div>
    </div>
    
    </>
  )
}

export default Heroabt