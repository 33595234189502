import React from 'react';
import styles from './AboutHero.module.css'
import abouthero from '../../../Assets/About/about_hero.mp4'
import animation from '../../../Assets/About/animation1.gif'

const AboutHero = () => {
  return ( 
    <> 

      <div className={styles.about_hero}>
        <video className={styles.video_bg} src={abouthero} autoPlay loop muted />
        <div className={styles.video_overlay}>

        </div>
        <div className={styles.about_hero_text}>
          <h1>YESKONDO</h1>
          <p>We are the creative design agency</p>
        </div>
      </div>
      {/* About main */}
      <div className={styles.about_main}>
        <div className={styles.about_main_content}>
            <h1>About Us</h1>
            <img src={animation} alt='Yeskondo About Animation' />

            <div className={styles.about_content_head}>
                <h2>We are a creative agency based in  Pune - India</h2>
                <p>At Yeskondo Media PVT. Ltd., established in 2020, we pride ourselves on being a dynamic media solutions provider.
              Specializing in 360 Media, we offer a comprehensive suite of services to elevate your brand presence.
              Our team is dedicated to delivering innovative solutions tailored to meet your specific needs.
              With a focus on creativity, strategy, and execution, we strive to exceed expectations and drive impactful results for our clients.
              Partner with us to unlock the full potential of your brand in today's competitive landscape.</p>
            </div>
        </div>
      </div>

    </>
  );
};

export default AboutHero;
