import React from 'react'
import styles from './Event.module.css'
import checkmar from '../../../Assets/check.png'
import event from '../../../Assets/Event/banner.png'
import management from '../../../Assets/Event/management.png'
import Logistics from '../../../Assets/Event/Logistics.png'
import Eventhero from '../../../Assets/Event/event_hero.png'
import Eventplaning from '../../../Assets/Event/event_planning.gif'
import Postevent from '../../../Assets/Event/Postevent.png'
import Flawless from '../../../Assets/Event/Flawless.png'
import Seamless from '../../../Assets/Event/Seamless.png'
import { Link } from 'react-router-dom'


const Tvc = () => {
    return (
        <>
            <div className={styles.oohmedia}>



                <div className={styles.oohmedia_flexbox}>
                    <div className={styles.oohmedia_left}>
                        <h1 className={styles.orange}>Event Planning & Execution</h1>


                        <div className={styles.oohicons}>
                            <div className={styles.oohicon}>
                                <img src={Flawless} alt="Flawless" />
                                {/* <h4>1T</h4> */}
                                <p>Flawless Event Coordination</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={Seamless} alt="Screens Icon" />
                                {/* <h4>1.8M</h4> */}
                                <p>Seamless Event Execution</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={event} alt="Spot Types Icon" />
                                {/* <h4>75</h4> */}
                                <p>Expert Event Management</p>
                            </div>
                        </div>
                        <Link to='/contact'><button className={styles.bookDemoButton}>Connect With Us</button></Link>
                    </div>
                    <div className={styles.ooh_right}>
                        <img src={Eventhero} alt='OOH media' className={styles.ooh_right_image} />
                    </div>
                </div>
            </div>

            {/* Main Content  */}

            <div className={styles.ooh_maincontent}>
                <h2> <span className={styles.orange}>Event Planning & Execution</span> </h2>
                <div className={styles.graphics}>
                    <div className={styles.ooh_main_content_graphics}>
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Event Planning & Execution</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Venue selection and logistics</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />On-site management and coordination</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Post-event analysis and reporting</li>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={event} alt='public Transport' />
                                <h4>Event Planning & Execution</h4>

                                <p>Event planning and execution involve meticulous coordination and strategic planning to create memorable experiences. From corporate events to product launches, every detail is managed to ensure seamless execution and impactful results.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.black_data} `}>

                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>
                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={Logistics} alt='Logistics' />
                                <h4>Venue Selection and Logistics</h4>

                                <p>Choosing the right venue and managing logistics are crucial for the success of any event. This includes selecting a location that fits the event's theme and audience, while also coordinating transportation, accommodations, and on-site services.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <img src={management} alt='management' />
                                <h4>On-site management and coordination</h4>

                                <p>Ensuring seamless execution through on-site management and coordination. This includes overseeing event setup, managing staff, and addressing any issues that arise to ensure a smooth and successful event.</p>
                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.just_image} `}>
                                <img src={Eventplaning} alt='Eventplaning' />

                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_push} `}>
                                <img src={Postevent} alt='Postevent' />
                                <h4>Post-Event Analysis and Reporting</h4>

                                <p>Comprehensive review and evaluation of event success and key performance metrics. Detailed reports provide insights for future improvements and strategic planning.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Content */}

            <div className={styles.alt_advertising}>
                <div className={styles.alt_advertising_flexbox}>
                    <div className={styles.alt_advertising_left}>
                        <img src={Eventhero} alt='TVC' />
                    </div>

                    <div className={styles.alt_advertising_right}>
                        <p>Event Planning & Execution at Yeskondo Media involves every aspect of organizing and managing successful events. From conceptualization and planning to venue selection and logistics, we handle it all. Our on-site management and coordination ensure smooth execution, while post-event analysis and reporting provide insights for future improvements. Whether it's a corporate event, product launch, or conference, we create memorable experiences that align with your brand.</p>
                    </div>
                </div>

            </div>

            {/* Create Campaign */}

            <div className={styles.campaign}>
                <div className={styles.campaign_content}>
                    <h2>Create Your  Campaign With <span className={styles.orange} >Yeskondo</span></h2>
                    <Link to='/contact'><button className={styles.bookDemoButton}>Create</button></Link>
                </div>

            </div>


        </>
    )
}

export default Tvc