import React from 'react';
import { Link } from 'react-router-dom';
import styles from './DigitalMedia.module.css';

import checkmar from '../../../Assets/check.png'

import digitalhero from '../../../Assets/Digital/digitalhero.jpg'
import web_developer from '../../../Assets/Digital/web_developer.gif'
import ads from '../../../Assets/Digital/ads.png'
import target from '../../../Assets/Digital/target.png'
import responsive from '../../../Assets/Digital/responsive.png'
import search_engine from '../../../Assets/Digital/search_engine.png'
import digitalhero2 from '../../../Assets/Digital/digitalhero2.jpg'

import Product from '../../../Assets/Digital/Project.png'
import Campaign from '../../../Assets/Digital/Campaign.png'
import website from '../../../Assets/Digital/webdevelopment.png'


const DigitalMedia = () => {
  return (
    <>
      {/* ooh media hero section  */}
      <div className={styles.oohmedia}>

        <div className={styles.oohmedia_flexbox}>
          <div className={styles.oohmedia_left}>
            <h1 className={styles.orange}>Digital Marketing</h1>
           

            <p>Capture audience attention on the go.</p>
            <div className={styles.oohicons}>
              <div className={styles.oohicon}>
                <img src={Product} alt="Product Completed" />
                <h4> 250 +</h4>
                <p>Project Completed</p>
              </div>
              <div className={styles.oohicon}>
                <img src={Campaign} alt="Successful Campaign" />
                <h4>245</h4>
                <p>Successful Campaign</p>
              </div>
              <div className={styles.oohicon}>
                <img src={website} alt="Website Launched" />
                <h4> 40 +</h4>
                <p>Website Launched</p>
              </div>
            </div>
            <Link to='/contact'><button className={styles.bookDemoButton}>Connect With Us</button></Link>
          </div>
          <div className={styles.ooh_right}>
            <img src={digitalhero} alt='digitalhero' className={styles.ooh_right_image} />
          </div>
        </div>

      </div>

      {/* ooh media main content */}
      <div className={styles.ooh_maincontent}>
        <h2> <span className={styles.orange}>Digital </span>Media Planning</h2>
        <div className={styles.graphics}>
          <div className={styles.ooh_main_content_graphics}>
            <div className={styles.advertising_data_main}>

              <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                <li><img src={checkmar} alt='check' className={styles.checkmark} />	Strategic online advertising campaigns</li>
                <li><img src={checkmar} alt='check' className={styles.checkmark} />Targeted audience analysis</li>
                <li><img src={checkmar} alt='check' className={styles.checkmark} />Multi-platform integration </li>
                <li><img src={checkmar} alt='check' className={styles.checkmark} />SEO (Search Engine Optimization)</li>
                <li><img src={checkmar} alt='check' className={styles.checkmark} />SMM (Social Media Marketing)</li>
                <li><img src={checkmar} alt='check' className={styles.checkmark} />SMO (Social Media Optimization)</li>
              </div>

              <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                <img src={ads} alt='ads' />
                <h4>Strategic online advertising campaigns</h4>

                <p>Strategic online advertising campaigns drive targeted traffic to your website, maximizing ROI through precise audience targeting and data-driven insights. These campaigns leverage various digital channels to enhance brand visibility and engagement.</p>
              </div>

              <div className={`${styles.adverting_data} ${styles.black_data} `}>

              </div>
            </div>

            {/*  */}
            <div className={styles.advertising_data_main}>
              <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                <img src={target} alt='target' />
                <h4>Targeted audience analysis</h4>

                <p>Targeted audience analysis identifies specific demographic and behavioral traits to tailor marketing strategies effectively.</p>
              </div>

              <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                <img src={responsive} alt='responsive' />
                <h4>Multi-platform integration</h4>
               
                <p>Multi-platform integration ensures consistent messaging and engagement across various digital channels for a cohesive brand experience.</p>
              </div>
            </div>

            {/*  */}
            <div className={styles.advertising_data_main}>

              <div className={`${styles.adverting_data} ${styles.just_image} `}>
                <img src={web_developer} alt='webdevelopment' />
                

              </div>

              <div className={`${styles.adverting_data} ${styles.advertising_push} `}>
                <img src={search_engine} alt='search_engine' />
                <h4>SEO (Search Engine Optimization)</h4>
                
                <p>SEO (Search Engine Optimization) enhances your website's visibility on search engines, driving organic traffic and improving search rankings.</p>
              </div>
            </div>
          </div>
        </div>
      </div>



{/* Content */}

<div className={styles.alt_advertising}>
<div className={styles.alt_advertising_flexbox}>
    <div className={styles.alt_advertising_left}>
        <img src={digitalhero2} alt='digitalhero2' />
    </div>

    <div className={styles.alt_advertising_right}>
        <p>Our Digital Marketing services help you reach your target audience effectively across multiple online platforms. We create strategic advertising campaigns based on detailed audience analysis, ensuring your message is seen by those who matter most. From social media and search engines to display ads, we integrate your campaigns seamlessly across various digital touchpoints. Our comprehensive services include SEO to improve your website's visibility, SEM for paid search advertising, SMM to engage with your audience on social media, and SMO to optimize your social media presence. Our team continuously monitors performance, making data-driven optimizations to maximize your ROI.</p>
    </div>
</div>

</div>


      {/* Create Campaign */}

      <div className={styles.campaign}>
        <div className={styles.campaign_content}>
          <h2>Create Your  Campaign With <span className={styles.orange} >Yeskondo</span></h2>
        <Link to='/contact'> <button className={styles.bookDemoButton}>Create</button></Link> 
        </div>

      </div>
    </>
  );
};

export default DigitalMedia;
