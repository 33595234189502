import React from 'react';
import styles from './Portfolio.module.css';


import 'react-image-lightbox/style.css';

const Portfolio = () => {
   





    return (
        <>
            <div className={styles.portfolio}>
                <div className={styles.container}>
                    <div className={styles.flexbox}>
                        <div className={`${styles.box} ${styles.left}`}>
                            <div className={styles.box_content}>
                                <h2>Creative Portfolio <span className={styles.color_orange}>Designs</span></h2>
                            </div>
                        </div>
                        <div className={`${styles.box} ${styles.right}`}>
                            <div className={styles.box_content}>
                                <p>Welcome to Yeskondo, where we specialize in crafting captivating and innovative portfolio designs that resonate with your unique style and creativity. Our approach combines cutting-edge design trends with seamless user experience, ensuring your portfolio stands out across all devices. Whether you're an artist, photographer, or designer, we're dedicated to creating visually stunning portfolios that leave a lasting impression. Let Yeskondo elevate your online presence with our bespoke portfolio solutions tailored to showcase your work beautifully.
                               </p>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        </>
    );
};

export default Portfolio;
