import React from 'react'
import styles from './Blt.module.css'


import blthero from '../../../Assets/BLT/btl_hero.png'
import checkmar from '../../../Assets/check.png'
import alt_media from '../../../Assets/Alt/alt_media.gif'
import target from '../../../Assets/BLT/target.png'
import magnet from '../../../Assets/BLT/magnet.png'
import promotion from '../../../Assets/BLT/promotion.png'
import result from '../../../Assets/BLT/result.png'
import bltmain from '../../../Assets/BLT/blt_main.png'
import engagement from '../../../Assets/BLT/engagement.png'
import productivity from '../../../Assets/BLT/productivity.png'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'



const Blt = () => {
    return (
        <>

            <Helmet>
                <title>Below-The-Line Media Planning - Yeskondo Media Pvt Ltd</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content="Discover Below-The-Line Media Planning services by Yeskondo Media Pvt Ltd. We specialize in targeted media solutions to effectively reach your audience." />
                <meta name="keywords" content="Below-The-Line media planning, Yeskondo Media Pvt Ltd, targeted media strategy, audience engagement, brand marketing" />
                <meta name="author" content="Yeskondo Media Pvt Ltd" />

                {/* Open Graph (OG) Tags */}
                <meta property="og:title" content="Below-The-Line Media Planning - Yeskondo Media Pvt Ltd" />
                <meta property="og:description" content="Explore how Yeskondo Media Pvt Ltd can elevate your brand through expert Below-The-Line Media Planning services." />
                <meta property="og:image" content="your-below-the-line-media-planning-og-image-url.jpg" />
                <meta property="og:url" content="https://www.yeskondo.com/services/below-the-line-media-planning" />

                {/* Twitter Card Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Below-The-Line Media Planning - Yeskondo Media Pvt Ltd" />
                <meta name="twitter:description" content="Yeskondo Media Pvt Ltd offers strategic Below-The-Line Media Planning services to help your brand connect directly with its target audience." />
                <meta name="twitter:image" content="your-below-the-line-media-planning-twitter-image-url.jpg" />
            </Helmet>


            <div className={styles.oohmedia}>
                <div className={styles.oohmedia_flexbox}>
                    <div className={styles.oohmedia_left}>
                        <h1 className={styles.orange}>BTL MEDIA PLANNING</h1>
                        <h2>Below-The-Line Media Planning  </h2>

                        <div className={styles.oohicons}>
                            <div className={styles.oohicon}>
                                <img src={target} alt="Targeted Promotions Executed" />
                                {/* <h4>1T</h4> */}
                                <p>Targeted Promotions Executed</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={engagement} alt="Direct Engagement Strategies" />
                                {/* <h4>1.8M</h4> */}
                                <p>Direct Engagement Strategies</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={productivity} alt="Effective Local Campaigns" />
                                {/* <h4>75</h4> */}
                                <p>Effective Local Campaigns</p>
                            </div>
                        </div>
                        <Link to='/contact'><button className={styles.bookDemoButton}>Connect With Us</button></Link>
                    </div>
                    <div className={styles.ooh_right}>
                        <img src={blthero} alt='OOH media' className={styles.ooh_right_image} />
                    </div>
                </div>
            </div>

            {/* Main Content  */}

            <div className={styles.ooh_maincontent}>
                <h2> <span className={styles.orange}>BTL</span> Media Planning</h2>
                <div className={styles.graphics}>
                    <div className={styles.ooh_main_content_graphics}>
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Targeted marketing activities</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Direct consumer engagement</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Experiential marketing and promotions</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Measurable results</li>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={target} alt='target' />
                                <h4>Targeted Marketing Activities</h4>

                                <p>We employ targeted marketing activities to engage specific audiences, ensuring precise brand messaging and higher conversion rates.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.black_data} `}>

                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>
                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={magnet} alt='magnet' />
                                <h4>Direct consumer engagement</h4>

                                <p>We foster direct consumer engagement to build meaningful connections, enhancing brand loyalty and driving customer satisfaction.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <img src={promotion} alt='promotion' />
                                <h4>Experiential marketing and promotions</h4>

                                <p>We create immersive experiences and promotions that engage consumers directly, leaving lasting impressions and driving brand loyalty.</p>
                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.just_image} `}>
                                <img src={alt_media} alt='alt_media' />

                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_push} `}>
                                <img src={result} alt='result' />
                                <h4>Measurable results</h4>

                                <p>We focus on campaigns that deliver quantifiable outcomes, ensuring every marketing activity can be tracked and analyzed for effectiveness.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Content */}

            <div className={styles.alt_advertising}>
                <div className={styles.alt_advertising_flexbox}>
                    <div className={styles.alt_advertising_left}>
                        <img src={bltmain} alt='Blt Media Planing' />
                    </div>

                    <div className={styles.alt_advertising_right}>
                        <p>Below-The-Line (BTL) Media Planning involves targeted marketing activities that engage consumers directly. We specialize in experiential marketing, promotions, and other direct engagement tactics that create memorable interactions with your brand. Our BTL strategies are designed to deliver measurable results, making every campaign a meaningful touchpoint for your audience.</p>
                    </div>
                </div>

            </div>

            {/* Create Campaign */}

            <div className={styles.campaign}>
                <div className={styles.campaign_content}>
                    <h2>Create Your  Campaign With <span className={styles.orange} >Yeskondo</span></h2>
                    <Link to='/contact'><button className={styles.bookDemoButton}>Create</button></Link>
                </div>

            </div>

        </>
    )
}

export default Blt