import React from 'react'
import styles from './Contact.module.css'
import '@fortawesome/fontawesome-free/css/all.min.css';

import { Helmet } from 'react-helmet';
const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - Yeskondo Media Pvt Ltd</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="Get in touch with Yeskondo Media Pvt Ltd for digital marketing, web development, and SEO services. Contact us today to discuss your project and enhance your digital presence." />
        <meta name="keywords" content="Yeskondo Media Pvt Ltd contact, contact Yeskondo Media, digital marketing contact, web development contact, SEO services contact" />
        <meta name="author" content="Yeskondo Media Pvt Ltd" />

        {/* Open Graph (OG) Tags */}
        <meta property="og:title" content="Contact Us - Yeskondo Media Pvt Ltd" />
        <meta property="og:description" content="Contact Yeskondo Media Pvt Ltd for expert digital marketing, web development, and SEO services. We’re here to help you boost your online presence." />
        <meta property="og:image" content="your-contact-page-og-image-url.jpg" />
        <meta property="og:url" content="https://www.yeskondo.com/contact" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - Yeskondo Media Pvt Ltd" />
        <meta name="twitter:description" content="Reach out to Yeskondo Media Pvt Ltd for expert solutions in digital marketing, web development, and SEO. Let’s discuss how we can transform your digital presence." />
        <meta name="twitter:image" content="your-contact-page-twitter-image-url.jpg" />
      </Helmet>

      <div className={styles.contact_page}>
        <div className={styles.contact_page_hero}>

        </div>

        <div className={styles.contact_header}>
          <div className={styles.contact_header_icons}>
            <div className={styles.icon_holder}>
              <i class="fa-solid fa-location-dot"></i>
              <p>Address:- Deccan, Pune -Maharashtra</p>
            </div>

            <div className={styles.icon_holder}>
              <i class="fa-solid fa-phone"></i>
              <p>Phone:- +91 99229 66739</p>
            </div>

            <div className={styles.icon_holder}>
              <i class="fa-solid fa-envelope"></i>
              <p>Email:- info@yeskondomedia.com</p>
            </div>

          </div>
        </div>
        <div className={styles.contact_form_main}>
          <div className={styles.contactform_flexbox}>
            <div className={styles.contact_form}>
              <h2>Contact Us</h2>
              <form>

                <div className={styles.flexbox}>
                  <div className={styles.form_group}>
                    <label>Full Name</label>
                    <input type="text" placeholder="Name" />
                  </div>


                  <div className={styles.form_group}>
                    <label>Email Address</label>
                    <input type="email" placeholder="Email" />
                  </div>
                </div>



                <div className={styles.form_group}>
                  <label>Subject</label>
                  <input type="text" placeholder="Subject" />
                </div>
                <div className={styles.form_group}>
                  <label>Message</label>
                  <textarea placeholder="Message"></textarea>
                </div>
                <button type="submit" className={styles.submit_button}>Send Message</button>
              </form>
            </div>
            <div className={styles.contact_form_image}>
              {/* <img src={contactimage} alt="Contact" /> */}
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default Contact
