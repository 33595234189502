import React from 'react'
import styles from './Offsetdesign.module.css'
import Offsethero from '../../../Assets/Offset/Offset_hero.png'
import checkmar from '../../../Assets/check.png'
import paper from '../../../Assets/Offset/paper.png'
import Custom from '../../../Assets/Offset/custom.png'
import flyer from '../../../Assets/Offset/flyer.png'
import filmproduction from '../../../Assets/Offset/Large_scale_printing.gif'
import Largeprint from '../../../Assets/Offset/Large_printer.png'
import { Link } from 'react-router-dom'

import Precision from '../../../Assets/Offset/Precision.png'
import Customize from '../../../Assets/Offset/Customize.png'
import quality from '../../../Assets/Offset/Highquality.png'

const Offsetdesigning = () => {
    return (
        <>
            <div className={styles.oohmedia}>



                <div className={styles.oohmedia_flexbox}>
                    <div className={styles.oohmedia_left}>
                        <h1 className={styles.orange}>Offset Designing & Printing</h1>


                        <div className={styles.oohicons}>
                            <div className={styles.oohicon}>
                                <img src={Precision} alt="Precision Print Design" />
                                {/* <h4>1T</h4> */}
                                <p>Precision Print Design</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={Customize} alt="Custom Offset Solutions" />
                                {/* <h4>1.8M</h4> */}
                                <p>Custom Offset Solutions</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={quality} alt="High-Quality Printing" />
                                {/* <h4>75</h4> */}
                                <p>High-Quality Printing</p>
                            </div>
                        </div>
                        <Link to='/contact'><button className={styles.bookDemoButton}>Connect With Us</button></Link>
                    </div>
                    <div className={styles.ooh_right}>
                        <img src={Offsethero} alt='OOH media' className={styles.ooh_right_image} />
                    </div>
                </div>
            </div>

            {/* Main Content  */}

            <div className={styles.ooh_maincontent}>
                <h2> <span className={styles.orange}>Offset Designing & Printing</span> </h2>
                <div className={styles.graphics}>
                    <div className={styles.ooh_main_content_graphics}>
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />High-quality offset printing</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Custom design services</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Brochures, flyers, and business cards</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Large-scale printing projects</li>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={paper} alt='paper' />
                                <h4>High-quality offset printing</h4>

                                <p>Achieving superior print quality with vibrant colors and fine details for various media. Ideal for large print runs, ensuring consistency and precision in every piece.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.black_data} `}>

                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>
                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src={Custom} alt='Custom' />
                                <h4>Custom design services</h4>

                                <p>Tailored design solutions to meet unique branding and marketing needs. From concept to execution, creating visually compelling and effective designs.</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <img src={flyer} alt='management' />
                                <h4>Brochures, flyers, and business cards</h4>

                                <p>Ensuring seamless execution through on-site management and coordination. This includes overseeing event setup, managing staff, and addressing any issues that arise to ensure a smooth and successful event.</p>
                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.just_image} `}>
                                <img src={filmproduction} alt='Film Production' />

                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_push} `}>
                                <img src={Largeprint} alt='Largeprint' />
                                <h4>Large-scale printing projects</h4>

                                <p>Expertly managing extensive printing projects to ensure quality and consistency. Ideal for billboards, banners, and large promotional materials.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Content */}

            <div className={styles.alt_advertising}>
                <div className={styles.alt_advertising_flexbox}>
                    <div className={styles.alt_advertising_left}>
                        <img src={Offsethero} alt='TVC' />
                    </div>

                    <div className={styles.alt_advertising_right}>
                        <p>Offset Designing & Printing at Yeskondo Media offers high-quality printing solutions for a variety of needs. Our custom design services ensure that your brochures, flyers, business cards, and other printed materials are visually appealing and professional. We handle large-scale printing projects with precision and efficiency, delivering exceptional results every time.</p>
                    </div>
                </div>

            </div>

            {/* Create Campaign */}

            <div className={styles.campaign}>
                <div className={styles.campaign_content}>
                    <h2>Create Your  Campaign With <span className={styles.orange} >Yeskondo</span></h2>
                    <Link to='/contact'><button className={styles.bookDemoButton}>Create</button></Link>
                </div>

            </div>


        </>
    )
}

export default Offsetdesigning