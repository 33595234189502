import React from 'react';
import Slider from 'react-slick';
import styles from './Clients.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import user from '../../../Assets/Home/profile.png';
import varsha from '../../../Assets/Home/varsha.png'
import manisha from '../../../Assets/Home/Manisha.jpg'
import chate from '../../../Assets/Home/Chate.jpg'


const Clients = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className={styles.clients}>
      <div className={styles.clients_container}>
        <div className={styles.clients_left}>
          <h4>Reviews</h4>
          <h2>They Talk About Us</h2>
        </div>
        <div className={styles.clients_right}>
          <Slider {...settings}>
            
            <div className={styles.client_review}>
              <p className={styles.quote}>“</p>
              <p className={styles.review_text}>
              My association with Lalit sir the founder of Yeskondo goes a long way. It has been almost 8 years we are associated and to be true they as a-team are amazing....their ideas , their strategies and suggestions have really worked for my Preschool. All the best 🙏.
              </p>
              <div className={styles.client_info}>
                <img src={manisha} alt="Manisha Bhawar" />
                <div>
                  <h3>Manisha Bhawar</h3>
                  <p>Director, Jump Thru Joy Preschool</p>
                </div>
              </div>
            </div>


            <div className={styles.client_review}>
              <p className={styles.quote}>“</p>
              <p className={styles.review_text}>
              Our experience with your team's advertising service for our school was exceptional. Their professionalism, creativity, and attention to detail exceeded our expectations. Communication was always prompt, and they were readily available for any queries. We are highly satisfied and would strongly recommend your services to others.
              </p>
              <div className={styles.client_info}>
                <img src={varsha} alt="Varsha Phukane" />
                <div>
                  <h3>Varsha Phukane</h3>
                  <p>Principal Hillgreen Highschool & Jr College</p>
                </div>
              </div>
            </div>

            <div className={styles.client_review}>
              <p className={styles.quote}>“</p>
              <p className={styles.review_text}>
              This team of 360 media consultant works very effectively, which company needs to study how marketing is done and properly deliver the message to the public, it seems that their product is of the best quality but because it You can't spread your message in social through different mediums, because it doesn't give results, but Lalit of 360 media consultant studies it properly and gives 100% result of quality work from his team, Salut Lalit & your team. work,, your future is very good
              </p>
              <div className={styles.client_info}>
                <img src={chate} alt="Prof. Fulchand Chate" />
                <div>
                  <h3>Prof. Fulchand Chate</h3>
                  <p>Founder (Chate Public School)</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Clients;
