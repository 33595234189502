import React from 'react'
import styles from './Footer.module.css'
import Logo from '../../Assets/Logo.png'
import { Link } from 'react-router-dom'
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
    return (
        <>
            <div className={styles.footer}>
                <div className={styles.footer_flexbox}>
                    <div className={styles.footer_left}>
                        <img src={Logo} alt='Logo' />
                        <p>At Yeskondo Media Pvt. Ltd., established in 2020, we pride ourselves on being a dynamic media solutions provider.</p>
                    </div>
                    <div className={styles.footer_right}>
                        <div className={styles.footer_links}>
                            <h2>Quick Links</h2>
                            <ul className={styles.footer_link_list}>
                                <li><Link to='/' className={styles.links} >Home</Link></li>
                                <li><Link to='/about' className={styles.links}>About</Link></li>
                                <li><Link to='/' className={styles.links}>Solution</Link></li>
                                {/* <li> <Link className={styles.links}>SEO</Link></li> */}
                                <li><Link to='/contact' className={styles.links}>Contact</Link></li>
                            </ul>
                        </div> 

                        <div className={styles.footer_links}>
                            <h2>Contact</h2>
                            <ul className={`${styles.footer_link_list} ${styles.icon_color} `}  >
                                <li className={styles.mb}><i class="fa-solid fa-location-dot"></i> Deccan, Pune -Maharashtra</li>
                                <li className={styles.mb}><i class="fa-solid fa-phone"></i>+91 99229 66739</li>
                                <li><i class="fa-solid fa-envelope"></i> info@yeskondomedia.com</li>
                            </ul>
                        </div>
                        <div className={`${styles.footer_links} ${styles.footer_icons}`}>
                            <h2>Social</h2>
                            <ul className={`${styles.footer_link_list} ${styles.social_icons}`}>
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/yeskondomedia">
                                    <li className={styles.facebook}>
                                        <i class="fab fa-facebook"></i>  </li>
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://x.com/yeskondomedia">
                                    <li className={styles.twitter}>  <i class="fab fa-twitter"></i>  </li>
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/yeskondo-media-pvt-ltd">  <li className={styles.linkedin}>  <i class="fab fa-linkedin"></i>   </li>
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/yeskondo_media_pvt_ltd_/">
                                    <li className={styles.instagram}>
                                        <i class="fab fa-instagram"></i>  </li>
                                </a>
                            </ul>
                        </div>
 
                    </div>
                </div>
                <div className={styles.footer_bottom}>
                    <p>© Copyright 2024 -Yeskondo</p>
                </div>
            </div>

        </>
    )
}

export default Footer