import React from 'react'

import styles from './Alt.module.css'
import althero from '../../../Assets/Alt/alt_hero.png'

import checkmar from '../../../Assets/check.png'
import altmedai from '../../../Assets/Alt/altmedia.png'
import alt_media from '../../../Assets/Alt/alt_media.gif'
import megaphone from '../../../Assets/Alt/megaphone.png'
import cast from '../../../Assets/Alt/cast.png'
import target from '../../../Assets/Alt/target.png'
import spam from '../../../Assets/Alt/spam.png'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
const Alt = () => {
    return (
        <>
            <Helmet>
                <title>Alternative Media Services - Yeskondo Media Pvt Ltd</title>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content="Explore Alternative Media services by Yeskondo Media Pvt Ltd. Discover how we help your brand stand out with unique media strategies tailored to your audience." />
                <meta name="keywords" content="Alternative media, Yeskondo Media Pvt Ltd, media strategy, unique advertising, digital marketing, brand engagement" />
                <meta name="author" content="Yeskondo Media Pvt Ltd" />

                {/* Open Graph (OG) Tags */}
                <meta property="og:title" content="Alternative Media Services - Yeskondo Media Pvt Ltd" />
                <meta property="og:description" content="Learn about Alternative Media services by Yeskondo Media Pvt Ltd and how we can elevate your brand through innovative media solutions." />
                <meta property="og:image" content="your-alternative-media-page-og-image-url.jpg" />
                <meta property="og:url" content="https://www.yeskondo.com/services/alternative-media" />

                {/* Twitter Card Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Alternative Media Services - Yeskondo Media Pvt Ltd" />
                <meta name="twitter:description" content="Yeskondo Media Pvt Ltd offers Alternative Media services to help your brand connect with audiences in unique and impactful ways." />
                <meta name="twitter:image" content="your-alternative-media-page-twitter-image-url.jpg" />
            </Helmet>

            <div className={styles.oohmedia}>
                <div className={styles.oohmedia_flexbox}>
                    <div className={styles.oohmedia_left}>
                        <h1 className={styles.orange}>ALT MEDIA</h1>
                        <h2>Alternative media </h2>
                        <p>Capture audience attention on the go.</p>
                        <div className={styles.oohicons}>
                            <div className={styles.oohicon}>
                                <img src={cast} alt="Broad Media Coverage" />
                                {/* <h4>1T</h4> */}
                                <p>Broad Media Coverage</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={target} alt="Mass Market Strategy" />
                                {/* <h4>1.8M</h4> */}
                                <p>Mass Market Strategy</p>
                            </div>
                            <div className={styles.oohicon}>
                                <img src={spam} alt="Comprehensive Campaign Design" />
                                {/* <h4>75</h4> */}
                                <p>Comprehensive Campaign Design</p>
                            </div>
                        </div>
                        <Link to='/contact'><button className={styles.bookDemoButton}>Connect With Us</button></Link>
                    </div>
                    <div className={styles.ooh_right}>
                        <img src={althero} alt='OOH media' className={styles.ooh_right_image} />
                    </div>
                </div>
            </div>

            {/* Main Content  */}

            <div className={styles.ooh_maincontent}>
                <h2> <span className={styles.orange}>ALT</span> Media Planning</h2>
                <div className={styles.graphics}>
                    <div className={styles.ooh_main_content_graphics}>
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Broad reach advertising</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Television, radio, and print media</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />Brand awareness campaigns</li>
                                <li><img src={checkmar} alt='check' className={styles.checkmark} />High-frequency exposure</li>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src='https://cdn.prod.website-files.com/60859154db955843c7fc06e7/65c7451e2bfec25927547467_ROI%20icon.svg' alt='Board' />
                                <h4>Broad Reach Advertising</h4>
                                <h1>90%</h1>
                                <p>of consumers are exposed to ads in high-traffic areas, ensuring maximum visibility</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.black_data} `}>

                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>
                            <div className={`${styles.adverting_data} ${styles.ml_auto} `}>
                                <img src='https://cdn.prod.website-files.com/60859154db955843c7fc06e7/65c744f9d92b5470dcd3c3d3_Capture%20Attention.svg' alt='Capture' />
                                <h4>Capture Attention</h4>
                                <h1>#3</h1>
                                <p>one of the top three channels most effectively capturing consumer attention</p>
                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_bullets} `}>
                                <img src={megaphone} alt='megaphone' />
                                <h4>Brand awareness campaigns</h4>

                                <p>Enhance your brand's visibility and recognition through targeted and strategic campaigns. Engage your audience across multiple channels to build lasting impressions and drive brand loyalty.</p>
                            </div>
                        </div>

                        {/*  */}
                        <div className={styles.advertising_data_main}>

                            <div className={`${styles.adverting_data} ${styles.just_image} `}>
                                <img src={alt_media} alt='alt_media' />

                            </div>

                            <div className={`${styles.adverting_data} ${styles.advertising_push} `}>
                                <img src='https://cdn.prod.website-files.com/60859154db955843c7fc06e7/65c7455cba1cd71484c9e50b_CTR%20icon.svg' alt='Push' />
                                <h4>Push Actions</h4>
                                <h1>66%</h1>
                                <p>of consumers report that engaging with a ad them to search for the brand online</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Content */}

            <div className={styles.alt_advertising}>
                <div className={styles.alt_advertising_flexbox}>
                    <div className={styles.alt_advertising_left}>
                        <img src={altmedai} alt='ALT Media Planing' />
                    </div>

                    <div className={styles.alt_advertising_right}>
                        <p>Above-The-Line (ATL) Media Planning focuses on broad-reach advertising to build brand awareness. We utilize traditional media channels such as television, radio, and print to deliver your message to a wide audience. Our ATL campaigns are designed to create high-frequency exposure, ensuring that your brand remains top-of-mind among consumers</p>
                    </div>
                </div>

            </div>

            {/* Create Campaign */}

            <div className={styles.campaign}>
                <div className={styles.campaign_content}>
                    <h2>Create Your  Campaign With <span className={styles.orange} >Yeskondo</span></h2>
                    <Link to='/contact'><button className={styles.bookDemoButton}>Create</button></Link>
                </div>

            </div>

        </>
    )
}

export default Alt